require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")

import "bootstrap";

import "../stylesheets/application.scss";

$(".alert-info" ).fadeOut(5000);
$(".alert-warning" ).fadeOut(5000);

// const selectElement = () => {
//   const triggers = document.getElementsByClassName("triggers");
//   for (let item of triggers) {
//     item.onclick = e => {
//       const parent = item.parentElement;
//       const width = parent.clientWidth
//       parent.classList.toggle("selected");
//       if (parent.classList.contains("selected")) {
//         parent.lastChild.style.cssText = `height:100%;width:${width}px;margin-left:-${width}px;margin-top:75px;`;
//       } else {
//         parent.lastChild.style.cssText = `height:0;width:0;margin-left:0;margin-top:0;`;
//       }
//     };
//   }
// };



const userDomEl = document.getElementById("app");
const idStonly = userDomEl ? userDomEl.dataset.idstonly : null;
const valueStonly = userDomEl ? userDomEl.dataset.valuestonly : null;
const email = userDomEl ? JSON.parse(userDomEl.dataset?.user).email : null;
const isTrueSet = valueStonly == 'true';

if (!email.includes("lecercledeslangues")) {
    StonlyWidget('identify', idStonly, {
    'firstvisit': isTrueSet,
    });
}
// MutationObserver = window.MutationObserver || window.WebKitMutationObserver;

// var observer = new MutationObserver(function(mutations, observer) {
//   selectElement();
// });

// var refElem = document.getElementsByClassName("app");

// if (refElem) {
//     observer.observe(document.getElementById("app"), {
//     subtree: true,
//     attributes: true
//     //...
//   });
// };
